import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, CssBaseline, Typography } from '@mui/material';
import CampaignList from './components/LeftPanel/CampaignList';
import Overview from './components/CenterPanel/Overview';
import DispatchList from './components/RightPanel/DispatchList';
import { getCampaigns, getCampaignLogs, getCampaignDays } from './services/supabase';

const theme = createTheme({
  palette: {
    primary: {
      main: '#25D366',
    },
    secondary: {
      main: '#FF6B6B',
    },
  },
});

function App() {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedDay, setSelectedDay] = useState('all');
  const [logs, setLogs] = useState([]);
  const [days, setDays] = useState([]);
  const [stats, setStats] = useState({
    totalEnvios: 0,
    sucessos: 0,
    falhas: 0,
  });

  useEffect(() => {
    loadCampaigns();
  }, []);

  useEffect(() => {
    if (selectedCampaign) {
      loadCampaignData();
    }
  }, [selectedCampaign, selectedDay]);

  const loadCampaigns = async () => {
    try {
      const data = await getCampaigns();
      setCampaigns(data);
      if (data.length > 0) {
        setSelectedCampaign(data[0]);
      }
    } catch (error) {
      console.error('Erro ao carregar campanhas:', error);
    }
  };

  const loadCampaignData = async () => {
    try {
      const [logsData, daysData] = await Promise.all([
        getCampaignLogs(selectedCampaign.id, selectedDay),
        getCampaignDays(selectedCampaign.id)
      ]);

      setLogs(logsData);
      setDays(daysData);

      // Calcula as estatísticas
      const totalEnvios = logsData.length;
      const sucessos = logsData.filter(log => log.sucesso === true).length;
      const falhas = logsData.filter(log => log.sucesso === false).length;
      
      setStats({
        totalEnvios,
        sucessos,
        falhas
      });
    } catch (error) {
      console.error('Erro ao carregar dados da campanha:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box 
        sx={{ 
          display: 'flex', 
          height: '100vh',
          width: '100vw',
          overflow: 'hidden',
          bgcolor: '#f8f9fa'
        }}
      >
        <Box sx={{ width: 300, flexShrink: 0 }}>
          <CampaignList
            campaigns={campaigns}
            selectedCampaign={selectedCampaign}
            onSelectCampaign={setSelectedCampaign}
          />
        </Box>
        <Box sx={{ 
          flex: 1, 
          overflowY: 'auto', 
          p: 3,
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              fontWeight: 500,
              mb: 4,
              color: '#1a1a1a'
            }}
          >
            PAINEL DE VISUALIZAÇÃO DE CAMPANHAS
          </Typography>
          <Overview
            campaign={selectedCampaign}
            stats={stats}
          />
        </Box>
        <Box sx={{ width: 380, flexShrink: 0 }}>
          <DispatchList
            days={days}
            selectedDay={selectedDay}
            onDayChange={setSelectedDay}
            logs={logs}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
