import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const getCampaigns = async () => {
  const { data, error } = await supabase
    .from('campanhas')
    .select(`
      *,
      dias (
        id,
        dia_numero,
        status
      )
    `)
    .order('created_at', { ascending: false });

  if (error) throw error;

  // Determina o status da campanha baseado nos dias
  return data.map(campaign => ({
    ...campaign,
    status: campaign.dias.some(day => day.status === 'em_execucao') ? 'running' :
            campaign.dias.some(day => day.status === 'pendente') ? 'pending' :
            'completed'
  }));
};

export const getCampaignLogs = async (campaignId, dayId = null) => {
  // Primeiro, buscar os dias da campanha
  const { data: campanhaDias } = await supabase
    .from('campanha_dias')
    .select('dia_id')
    .eq('campanha_id', campaignId);

  if (!campanhaDias) return [];

  // Pegar todos os IDs dos dias
  const diaIds = campanhaDias.map(cd => cd.dia_id);

  // Buscar os logs desses dias
  const { data: logs, error } = await supabase
    .from('logs')
    .select('*')
    .in('dia_id', diaIds)
    .order('enviado_em', { ascending: false });

  if (error) throw error;
  return logs || [];
};

export const getCampaignDays = async (campaignId) => {
  const { data, error } = await supabase
    .from('campanha_dias')
    .select(`
      dias:dia_id (
        id,
        dia_numero,
        inicio,
        status,
        tipo,
        texto_ou_legenda,
        midia_url,
        destinatarios_string,
        intervalo_minimo,
        intervalo_maximo
      )
    `)
    .eq('campanha_id', campaignId)
    .order('dia_id', { ascending: true });

  if (error) throw error;
  
  // Transformar o resultado para ficar mais simples
  return data?.map(item => item.dias) || [];
};
