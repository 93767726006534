import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Paper } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const StyledPanel = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '24px',
  backgroundColor: '#f5f5f5'
});

const Title = styled(Typography)({
  fontSize: '24px',
  fontWeight: 600,
  color: '#1a1a1a',
  marginBottom: '24px'
});

const MetricsContainer = styled(Box)({
  display: 'flex',
  gap: '24px',
  '@media (max-width: 768px)': {
    flexDirection: 'column'
  }
});

const MetricCard = styled(Paper)({
  flex: 1,
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  borderRadius: '16px',
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  backgroundColor: '#fff',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 8px 16px rgba(0,0,0,0.12)'
  }
});

const IconWrapper = styled(Box)(({ color }) => ({
  width: '56px',
  height: '56px',
  borderRadius: '16px',
  background: `linear-gradient(135deg, ${color} 0%, ${color}dd 100%)`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiSvgIcon-root': {
    color: '#fff',
    fontSize: '28px'
  }
}));

const MetricValue = styled(Typography)({
  fontSize: '32px',
  fontWeight: 600,
  color: '#1a1a1a',
  marginBottom: '4px'
});

const MetricLabel = styled(Typography)({
  fontSize: '16px',
  color: '#666',
  fontWeight: 500
});

const ChartContainer = styled(Paper)({
  flex: 1,
  padding: '24px',
  borderRadius: '16px',
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  backgroundColor: '#fff',
  marginTop: '24px'
});

const Overview = ({ campaign, stats }) => {
  console.log('Campaign:', campaign);
  console.log('Stats:', stats);

  const data = [
    { name: 'Jan', value: 400 },
    { name: 'Fev', value: 300 },
    { name: 'Mar', value: 600 },
    { name: 'Abr', value: 800 },
    { name: 'Mai', value: 500 }
  ];

  if (!campaign) {
    return (
      <Box sx={{ 
        p: 3, 
        textAlign: 'center', 
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Typography variant="h6" color="text.secondary">
          Selecione uma campanha para ver os detalhes
        </Typography>
      </Box>
    );
  }

  return (
    <StyledPanel>
      <Title>
        {campaign.nome}
      </Title>

      <MetricsContainer>
        <MetricCard>
          <IconWrapper color="#25D366">
            <WhatsAppIcon />
          </IconWrapper>
          <Box>
            <MetricValue>
              {stats?.totalEnvios || 0}
            </MetricValue>
            <MetricLabel>
              Total de Envios
            </MetricLabel>
          </Box>
        </MetricCard>

        <MetricCard>
          <IconWrapper color="#4CD964">
            <CheckCircleIcon />
          </IconWrapper>
          <Box>
            <MetricValue>
              {stats?.sucessos || 0}
            </MetricValue>
            <MetricLabel>
              Sucessos
            </MetricLabel>
          </Box>
        </MetricCard>

        <MetricCard>
          <IconWrapper color="#FF3B30">
            <ErrorIcon />
          </IconWrapper>
          <Box>
            <MetricValue>
              {stats?.falhas || 0}
            </MetricValue>
            <MetricLabel>
              Falhas
            </MetricLabel>
          </Box>
        </MetricCard>
      </MetricsContainer>

      <ChartContainer>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
            <XAxis dataKey="name" stroke="#666" />
            <YAxis stroke="#666" />
            <Tooltip />
            <Line 
              type="monotone" 
              dataKey="value" 
              stroke="#25D366" 
              strokeWidth={2}
              dot={{ stroke: '#25D366', strokeWidth: 2, r: 4, fill: '#fff' }}
              activeDot={{ r: 6, stroke: '#25D366', strokeWidth: 2, fill: '#fff' }}
            />
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
    </StyledPanel>
  );
};

export default Overview;
