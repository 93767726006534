import React from 'react';
import { styled } from '@mui/material/styles';
import { 
  Box, 
  Typography, 
  List, 
  ListItem, 
  ListItemAvatar, 
  Avatar, 
  ListItemText,
  FormControl,
  Select,
  MenuItem,
  IconButton
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

const StyledPanel = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#fff'
});

const Header = styled(Box)({
  padding: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '12px'
});

const Title = styled(Typography)({
  fontSize: '18px',
  fontWeight: 500,
  color: '#1a1a1a'
});

const StyledFormControl = styled(FormControl)({
  minWidth: 100,
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    backgroundColor: '#fff',
    '& fieldset': {
      border: 'none'
    },
    '&:hover fieldset': {
      border: 'none'
    },
    '&.Mui-focused fieldset': {
      border: 'none'
    }
  },
  '& .MuiSelect-select': {
    padding: '6px 28px 6px 12px',
    fontSize: '14px',
  }
});

const LogsList = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  padding: '16px'
});

const Footer = styled(Box)({
  padding: '16px',
  borderTop: '1px solid #f0f0f0',
  display: 'flex',
  justifyContent: 'flex-end'
});

const TotalText = styled(Typography)({
  fontSize: '14px',
  color: '#666'
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
  backgroundColor: '#f8f9fa',
  borderRadius: '8px',
  marginBottom: theme.spacing(1),
  '&:last-child': {
    marginBottom: 0,
  },
}));

const StatusIcon = styled(IconButton)(({ success }) => ({
  color: success ? '#4CD964' : '#FF6B6B',
  padding: 0,
}));

const DispatchList = ({ days, selectedDay, onDayChange, logs }) => {
  const formatDate = (date) => {
    return format(new Date(date), "dd 'de' MMMM", { locale: ptBR });
  };

  const formatTime = (date) => {
    return format(new Date(date), 'HH:mm');
  };

  // Filtrar apenas os dias concluídos
  const concludedDays = days?.filter(day => day.status === 'concluido') || [];
  
  // Filtrar logs baseado no dia selecionado
  const filteredLogs = logs?.filter(log => 
    selectedDay === 'all' ? true : log.dia_id === selectedDay
  ) || [];

  return (
    <StyledPanel>
      <Header>
        <Title>Disparos da campanha</Title>
        <StyledFormControl size="small">
          <Select
            value={selectedDay}
            onChange={(e) => onDayChange(e.target.value)}
            IconComponent={KeyboardArrowDownIcon}
            displayEmpty
          >
            <MenuItem value="all">Todos</MenuItem>
            {concludedDays.map((day) => (
              <MenuItem key={day.id} value={day.id}>
                Dia {day.dia_numero}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Header>

      <LogsList>
        <List>
          {filteredLogs.map((log) => (
            <StyledListItem key={log.id}>
              <ListItemAvatar>
                <Avatar src="/default-avatar.png" />
              </ListItemAvatar>
              <ListItemText
                primary={log.destinatario || "Número não disponível"}
                secondary={formatTime(log.enviado_em)}
              />
              <StatusIcon success={log.sucesso}>
                {log.sucesso ? <CheckCircleIcon /> : <CancelIcon />}
              </StatusIcon>
            </StyledListItem>
          ))}
        </List>
      </LogsList>

      <Footer>
        <TotalText>
          Total de disparos: {filteredLogs.length}
        </TotalText>
      </Footer>
    </StyledPanel>
  );
};

export default DispatchList;
