import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const StyledPanel = styled(Box)({
  width: '100%',
  height: '100vh',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column'
});

const Logo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '16px',
  '& .MuiSvgIcon-root': {
    fontSize: 32,
    color: '#25D366',
    marginRight: '8px'
  }
});

const Title = styled(Typography)({
  fontSize: '20px',
  fontWeight: 500,
  color: '#1a1a1a',
  padding: '16px'
});

const ScrollableList = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  padding: '16px'
});

const CampaignCard = styled(Box)(({ status }) => ({
  background: 
    status === 'running' ? 'linear-gradient(90deg, #FF6B6B 0%, #FF8E8E 100%)' :
    status === 'pending' ? 'linear-gradient(90deg, #FFD93D 0%, #FFA63D 100%)' :
    'linear-gradient(90deg, #4CD964 0%, #2ECC71 100%)',
  borderRadius: '12px',
  padding: '16px',
  marginBottom: '12px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateY(-2px)'
  }
}));

const CampaignInfo = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
});

const CampaignName = styled(Typography)({
  fontSize: '16px',
  fontWeight: 500,
  color: '#fff'
});

const CampaignDate = styled(Typography)({
  fontSize: '14px',
  color: 'rgba(255, 255, 255, 0.9)'
});

const StatusIcon = styled(Box)({
  '& .MuiSvgIcon-root': {
    fontSize: 24,
    color: '#fff'
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
});

const CampaignList = ({ campaigns, onSelectCampaign, selectedCampaign }) => {
  const getStatusIcon = (status) => {
    switch (status) {
      case 'running':
        return <AutorenewIcon sx={{ animation: 'spin 2s linear infinite' }} />;
      case 'pending':
        return <AccessTimeIcon />;
      default:
        return <CheckCircleIcon />;
    }
  };

  return (
    <StyledPanel>
      <Logo>
        <WhatsAppIcon />
        <Typography variant="h6">
          DISPARADOR
        </Typography>
      </Logo>
      
      <Title>
        Campanhas
      </Title>
      
      <ScrollableList>
        {campaigns.map((campaign) => (
          <CampaignCard
            key={campaign.id}
            status={campaign.status}
            onClick={() => onSelectCampaign(campaign)}
            sx={{
              boxShadow: selectedCampaign?.id === campaign.id 
                ? '0 4px 12px rgba(0,0,0,0.15)' 
                : '0 2px 8px rgba(0,0,0,0.1)'
            }}
          >
            <CampaignInfo>
              <CampaignName>
                {campaign.nome}
              </CampaignName>
              <CampaignDate>
                {new Date(campaign.created_at).toLocaleDateString()}
              </CampaignDate>
            </CampaignInfo>
            <StatusIcon>
              {getStatusIcon(campaign.status)}
            </StatusIcon>
          </CampaignCard>
        ))}
      </ScrollableList>
    </StyledPanel>
  );
};

export default CampaignList;
